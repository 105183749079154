import "./App.css";
import React, { lazy } from "react";
import NotFound from "./NotFound";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import StoreInfo from "./webhook/store info/StoreInfo";
// import StoresByTag from "./webhook/StoresByTag";
// import Webhook from "./webhook/Webhook";
// import Store from "./webhook/Store"

// Lazy load the webhook-not-related components
const Home = lazy(() => import("./home/Home"));
// const Contacts = lazy(()=> import("./contacts/Contacts.js"));
const Product = lazy(() => import("./product/Product"));
// const Order = lazy(()=> import("./order/Order.js"));
// const Chat = lazy(() => import("./webhook/chat/Chat/Chat"));
// const SellerShop = lazy(() => import("./webhook/sellers panel/StoreInfoSettings"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/*<Route path="/webhook/stores/tag/" element={<StoresByTag />}/>*/}
        {/*<Route path="/webhook/seller-orders" element={<StoreInfo />}/>*/}
        {/*<Route path="/webhook/seller-sales" element={<StoreInfo />}/>*/}
        {/*<Route path="/webhook/seller-shop" element={<SellerShop />}/>*/}
        {/*<Route path="/webhook/store-info" element={<StoreInfo />}/>*/}
        {/*<Route path="/webhook/store/" element={<Store />}/>*/}
        <Route path="/contacts" element={<Home />} />
        {/*<Route path="/webhook/chat" element={<Chat />} />*/}
        {/*<Route path="/webhook" element={<Webhook />} />*/}
        <Route path="/product" element={<Product />} />
        {/*<Route path="/order" element={<Order />} />*/}
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} status={404} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
